// src/Footer.js
import React from 'react';

function Footer() {
  return (
    <footer className="bg-dark text-white mt-5 p-4 text-center">
      © {new Date().getFullYear()} ROE VIsual US Inc. All rights reserved.
      <br />
      For any questions, please contact{' '}
      <a href="mailto:rma@roevisual.us" className="text-white">
        RMA@roevisual.us
      </a>
      .
    </footer>
  );
}

export default Footer;
