import React from 'react';
import { Container, Button, Alert } from 'react-bootstrap';

function StartRepair() {
  return (
    <Container className="py-5 text-center">
      <h1>Start a New RMA</h1>
      <p>Please download the RMA form, fill it out, and follow the instructions to start the repair process.</p>
      <Button href="/downloads/ROE USRD RMA Form  Ver. 200730.xlsx" download variant="primary">
        Download RMA Form
      </Button>
      <Alert variant="danger" className="mt-5">
        If you have any questions, please contact us at{' '}
        <a href="mailto:rma@roevisual.us">RMA@roevisual.us</a>.
      </Alert>
    </Container>
  );
}

export default StartRepair;
