// src/Layout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import backgroundImage from './assets/background.jpg'; // Import the background image
import './Layout.css'; // Import the CSS for the overlay
import logoImage from './assets/ROE LOGO - RGB-01.png'

function Layout() {
  return (
    <div className="layout">
      <Header />
      <div className="content text-white">
        <img src={logoImage} id="logo"/>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
