// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LandingPage from './LandingPage';
import StartRepair from './StartRepair';
import TrackRepair from './TrackRepair';
import Layout from './Layout';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<LandingPage />} />
        <Route path="start-repair" element={<StartRepair />} />
        <Route path="track-repair" element={<TrackRepair />} />
      </Route>
    </Routes>
  </Router>
);

reportWebVitals();
