import React from 'react';
import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function LandingPage() {
  return (
    <Container className="py-5 text-center">
      <h1>ROE Visual US RMA Service</h1>
      <p>Select an option below to proceed:</p>

      <Row className="mt-4">
        <Col>
          <Link to="/start-repair">
            <Button variant="primary" size="lg">
              Start a New RMA
            </Button>
          </Link>
        </Col>
        <Col>
          <Link to="/track-repair">
            <Button variant="success" size="lg">
              Track an Existing RMA
            </Button>
          </Link>
        </Col>
      </Row>

      <Alert variant="danger" className="mt-5">
        For any questions about the process, please reach out to{' '}
        <a href="mailto:RMA@roevisual.us">RMA@roevisual.us</a>.
      </Alert>
    </Container>
  );
}

export default LandingPage;
