import React, { useEffect, useState } from 'react';
import { Container, Form, Button, ProgressBar, Alert, Row, Col, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';



function TrackRepair() {
  const [jobNumber, setJobNumber] = useState('');
  const [status, setStatus] = useState(null);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const statuses = [
    'Inbound',
    'Received',
    'Checked In',
    'Repair',
    'QC',
    'PO Needed',
    'Outbound',
    'Finished'
  ];

  function getStatusMessage(status) {
    switch (status) {
      case 'Inbound':
        return 'Your repair item is on its way to our facility. We’ll notify you once it’s received.';
      case 'Received':
        return 'We’ve received your item and it’s now in our system. The next step is to check it in.';
      case 'Checked In':
        return 'Your item has been checked in and is awaiting repair. We’ll keep you updated on its progress.';
      case 'Repair':
        return 'Your item is currently being repaired. Our technicians are working on it.';
      case 'QC':
        return 'Your item is undergoing quality control checks to ensure everything is in order.';
      case 'PO Needed':
        return 'We’re waiting for a purchase order or additional information to proceed. Please contact us if you have any questions.';
      case 'Outbound':
        return 'Your item is being prepared for shipment back to you. You’ll receive a tracking number soon.';
      case 'Finished':
        return 'Your repair is complete! Your item is on its way back to you.';
      default:
        return 'We’re processing your item. Please check back later for more updates.';
    }
  }

  useEffect(() => {
    fetch('/api/backend-check')
      .then(response => response.text())
      .then(data => setMessage(data));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset status and error
    setStatus(null);
    setError('');

    // Validate inputs
    if (!jobNumber) {
      setError('Please enter the RMA number.');
      return;
    }

    try {
      // Make API request to your backend
      const response = await fetch('/api/rma-status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ jobNumber }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch RMA status');
      }

      const data = await response.json();
      setStatus(data.data.items_page_by_column_values.items[0].column_values[0].text); // Adjust this line based on your API response structure
    } catch (err) {
      setError('Error fetching RMA status, please double check your RMA number and try again. If this issue persists, please contact RMA@roevisual.us.');
    }
  };

  const calculateProgress = () => {
    if (!status) return 0;
    const index = statuses.indexOf(status);
    return (index + 1) / statuses.length * 100;
  };

  return (
    <Container className="py-5">
      <h1 className="text-center mb-4">RMA Tracker</h1>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formJobNumber">
          <Form.Label>RMA Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter RMA number"
            value={jobNumber}
            onChange={(e) => setJobNumber(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="mt-3">
          Check Status
        </Button>
      </Form>

      {status && (
        <>
        <div className="mt-4">
          <h4>Job Status: {status}</h4>
          <ProgressBar now={calculateProgress()} label={`${calculateProgress()}%`} animated striped variant="success" />

          <Row className="mt-3 text-center">
            {statuses.map((stage, index) => (
              <Col key={index}>
                <small>{stage}</small>
              </Col>
            ))}
          </Row>
        </div>

        {/* Info Box */}
        <Card className="mt-4">
        <Card.Body>
          <Card.Text>{getStatusMessage(status)}</Card.Text>
        </Card.Body>
      </Card>
      </>
      )}

      {error && (
        <Alert variant="danger" className="mt-3">
          {error}
        </Alert>
      )}

      {/* backend connection check */}
      {/* {message && <p className="mt-3">{message}</p>} */}
    </Container>
  );
}

export default TrackRepair;
